import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NavbarComponent } from './components/navbar/navbar.component';
import { PrimaryButtonComponent } from './components/button/primary-button.component';
import { ContainerComponent } from './components/container/container.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { OrderStateNavComponent } from './components/order-state-nav/order-state-nav.component';
import { ModalComponent } from './components/modal/modal.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PrintFormatComponent } from './components/print-format/print-format.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { HeaderTitleComponent } from './components/header-title/header-title.component';

@NgModule({ declarations: [
        AccordionComponent,
        CheckboxComponent,
        ContainerComponent,
        DatePickerComponent,
        HeaderTitleComponent,
        NavbarComponent,
        ModalComponent,
        OrderStateNavComponent,
        PrimaryButtonComponent,
        PrintFormatComponent,
        SidebarComponent,
        SpinnerComponent,
        TabsComponent,
        HeaderTitleComponent,
    ],
    exports: [
        AccordionComponent,
        CheckboxComponent,
        ContainerComponent,
        DatePickerComponent,
        HeaderTitleComponent,
        NavbarComponent,
        ModalComponent,
        OrderStateNavComponent,
        PrintFormatComponent,
        PrimaryButtonComponent,
        SidebarComponent,
        SpinnerComponent,
        TabsComponent,
    ], imports: [CommonModule, ReactiveFormsModule, RouterModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class SharedModule {}
