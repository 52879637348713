import { Component, Input } from '@angular/core';
import { SalesOrder } from '../../types/dynamo/salesOrder';

@Component({
  selector: 'order-state-nav',
  templateUrl: './order-state-nav.component.html',
  standalone: false,
})
export class OrderStateNavComponent {
  @Input() order!: SalesOrder;
}
