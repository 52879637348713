import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  Tab,
  TabType,
} from '../../../orders/interfaces/eyeglass-tabs.interface';
import { EyeglassesConfigService } from 'src/app/orders/services/eyeglasses-config.service';

@Component({
  selector: 'x-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css'],
  standalone: false,
})
export class TabsComponent implements OnInit {
  @Input() tabs: Tab[] = []; //conservar
  @Input() form!: FormGroup;

  tabTypes: TabType[] = Object.values(TabType);

  constructor(public eyeglassesConfigService: EyeglassesConfigService) {}

  ngOnInit() {
    this.tabs = this.eyeglassesConfigService.getTabsData();
  }

  selectTab(index: number) {
    this.eyeglassesConfigService.activeTabIndex = index;
  }

  openAddMenu() {
    this.eyeglassesConfigService.addMenuOpen =
      !this.eyeglassesConfigService.addMenuOpen;
  }

  addNewTab(tabType: TabType, active: boolean, canDelete: boolean) {
    this.eyeglassesConfigService.addNewTab(tabType, active, canDelete);
  }

  deleteTab(index: number) {
    this.eyeglassesConfigService.deleteTab(index);
  }
}
