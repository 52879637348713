<xcontainer class="mt-10 mb-4" *ngIf="userAuth0">
  <div class="w-full">
    <h1 class="text-lg font-semibold">
      Hola de nuevo, {{ userAuth0.name }}! 👋🏻
    </h1>
  </div>
</xcontainer>

<xcontainer class="bg-white rounded-md shadow-lg">
  <div
    class="flex justify-evenly items-center lg:flex-row md:flex-wrap sm:flex-col xs:flex-col w-full"
    *ngIf="user_role?.name == 'clientB2B'"
  >
    <!--Crear orden-->
    <div
      class="flex bg-xblue justify-center rounded-sm lg:my-40 md:my-10 sm:my-10 xs:my-10"
    >
      <div class="flex items-center basis-1/2">
        <a
          [routerLink]="['/orders/create_order']"
          class="flex flex-col w-[200px] p-10 justify-center items-center border rounded-sm text-white bg-xblue border-xblue cursor-pointer hover:bg-xblue-enfasis duration-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-plus-circle"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="16"></line>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </svg>
          <h5 class="mt-2 text-md text-center font-bold text-white">
            Crear pedido
          </h5>
        </a>
      </div>
    </div>
    <!--Mis ordenes-->
    <div
      class="flex bg-xblue justify-center rounded-sm lg:my-40 md:my-10 sm:my-10 xs:my-10"
    >
      <div class="flex items-center basis-1/2">
        <a
          [routerLink]="['/orders/my_orders']"
          class="flex flex-col w-[200px] p-10 justify-center items-center border rounded-sm text-white bg-xblue border-xblue cursor-pointer hover:bg-xblue-enfasis duration-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-archive"
          >
            <polyline points="21 8 21 21 3 21 3 8"></polyline>
            <rect x="1" y="3" width="22" height="5"></rect>
            <line x1="10" y1="12" x2="14" y2="12"></line>
          </svg>
          <h5 class="mt-2 text-md text-center font-bold text-white">
            Mis pedidos
          </h5>
        </a>
      </div>
    </div>
    <!--Informes Operativos-->
    <div
      class="flex bg-xblue justify-center rounded-sm lg:my-40 md:my-10 sm:my-10 xs:my-10"
    >
      <div class="flex items-center basis-1/2">
        <a
          [routerLink]="['/reports']"
          class="flex flex-col w-[200px] p-10 justify-center items-center border rounded-sm text-white bg-xblue border-xblue cursor-pointer hover:bg-xblue-enfasis duration-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-file-text"
          >
            <path
              d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
            ></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" y1="13" x2="8" y2="13"></line>
            <line x1="16" y1="17" x2="8" y2="17"></line>
            <polyline points="10 9 9 9 8 9"></polyline>
          </svg>
          <h5 class="mt-2 text-md text-center font-bold text-white">
            Informes
          </h5>
        </a>
      </div>
    </div>
  </div>
  <div
    class="flex justify-evenly items-center lg:flex-row md:flex-wrap sm:flex-col xs:flex-col w-full"
    *ngIf="user_role?.name == 'xtension-user'"
  >
    <!--Bandeja de entrada-->
    <div
      class="flex bg-xblue justify-center rounded-sm lg:my-40 md:my-10 sm:my-10 xs:my-10"
    >
      <div class="flex items-center basis-1/2">
        <a
          [routerLink]="['/inbox']"
          class="flex flex-col w-[200px] p-10 justify-center items-center border rounded-sm text-white bg-xblue border-xblue cursor-pointer hover:bg-xblue-enfasis duration-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-inbox"
          >
            <polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline>
            <path
              d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"
            ></path>
          </svg>
          <h5 class="mt-2 text-md text-center font-bold text-white">
            Bandeja de entrada
          </h5>
        </a>
      </div>
    </div>
  </div>
</xcontainer>
