import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientB2B } from 'src/app/shared/types/dynamo/clientB2B';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientB2bService {
  private api = env.apiUrl;

  constructor(private http: HttpClient) {}

  public getClientB2B(
    PK: ClientB2B['PK'],
    SK: ClientB2B['SK']
  ): Observable<ClientB2B> {
    return this.http.get<ClientB2B>(`${this.api}/optic/${PK}/${SK}`);
  }

  public getClientsB2B(PK: ClientB2B['PK']): Observable<ClientB2B[]> {
    return this.http.get<ClientB2B[]>(`${this.api}/optic/${PK}`);
  }

  public addClientB2B(clientB2B: string) {
    return this.http.post(`${this.api}/optic`, clientB2B);
  }

  public updateClientB2B(
    PK: ClientB2B['PK'],
    SK: ClientB2B['SK'],
    clientB2B: string
  ) {
    return this.http.put(`${this.api}/optic/${PK}/${SK}`, clientB2B);
  }

  public deleteClientB2B(PK: ClientB2B['PK'], SK: ClientB2B['SK']) {
    return this.http.delete(`${this.api}/optic/${PK}/${SK}`);
  }
}
