<xcontainer class="mt-8 mb-2">
  <div class="inline-flex items-center select-none cursor-default">
    <a [routerLink]="['/home']" class="text-xgreen bg-xbackground rounded-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-arrow-left-circle"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <polyline points="12 8 8 12 12 16"></polyline>
        <line x1="16" y1="12" x2="8" y2="12"></line>
      </svg>
    </a>
    <div class="w-full ml-4">
      <h1 class="text-lg font-semibold">
        {{ title }}
        <span *ngIf="workOrderID" class="text-xblue">{{ workOrderID }}</span>
      </h1>

      <p class="text-sm font-semibold text-xsubtitles">
        {{ subtitle }}
      </p>
    </div>
  </div>
</xcontainer>
