<div class="flex lg:flex-row md:flex-row sm:flex-col xs:flex-col">
  <aside
    [ngClass]="[
      open
        ? 'lg:w-20 md:w-20 sm:w-full xs:w-full'
        : 'lg:w-60 md:w-60 sm:w-full xs:w-full',
      ' duration-300 lg:fixed md:fixed'
    ]"
  >
    <div
      class="flex flex-col justify-between bg-xgreen lg:h-screen md:h-screen select-none"
    >
      <div class="logo px-4 py-6 space-y-6">
        <!--Logo-->

        <a
          [ngClass]="[
            'flex justify-between items-center',
            open ? 'lg:flex-col-reverse' : 'lg:inline-flex '
          ]"
        >
          <img
            src="../../../../assets/img/Full logo - BG green.PNG"
            alt="Full logo green"
            [ngClass]="[
              'w-44',
              open ? 'lg:hidden md:hidden sm:scale-1 xs:scale-1' : 'scale-1'
            ]"
          />

          <!--Expand sidebar lg/md-->

          <div
            class="w-auto p-2 hover:bg-xgreen-enfasis rounded-full duration-300"
          >
            <a
              (click)="toggle()"
              class="text-white cursor-pointer duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
          </div>
        </a>

        <!--Links-->

        <ul
          [ngClass]="[
            open
              ? 'sm:flex-col xs:flex-col lg:items-center md:items-center sm:text-right xs:text-right'
              : ' sm:hidden xs:hidden text-left',
            'links lg:flex lg:flex-col md:flex md:flex-col justify-center space-y-3 lg:mb-2 md:mb-2 sm:mb-6 xs:mb-6'
          ]"
        >
          <!--Inicio-->
          <li>
            <a
              [routerLink]="['/home']"
              [routerLinkActive]="['bg-xgreen-enfasis']"
              href="#"
              [ngClass]="[
                'inline-flex items-center p-2 text-sm font-normal rounded-sm text-white  hover:bg-xgreen-enfasis duration-300',
                open ? 'lg:w-auto md:w-auto sm:w-full xs:w-full ' : 'w-full'
              ]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-home"
              >
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                <polyline points="9 22 9 12 15 12 15 22"></polyline>
              </svg>
              <span
                [ngClass]="[
                  open
                    ? 'lg:hidden md:hidden ml-3 '
                    : 'flex-1 ml-3 whitespace-nowrap'
                ]"
                >Inicio</span
              >
            </a>
          </li>
          <!--LINK PRO
            <li>
              <a
                href="#"
                class="flex items-center p-2 text-sm font-normal text-gray-900 rounded-sm dark:text-white hover:bg-gray-100 dark:hover:bg-xgreen-enfasis dark:hover:w-full"
              >
                <svg
                  aria-hidden="true"
                  class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-slate-50 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  ></path>
                </svg>
                <span class="flex-1 ml-3 whitespace-nowrap">Kanban</span>
                <span
                  class="inline-flex  items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-slate-50"
                  >Pro</span
                >
              </a>
            </li>-->
          <!--Crear pedido-->
          <li *ngIf="user_role?.name == 'clientB2B'">
            <a
              [routerLink]="['/orders/create_order']"
              [routerLinkActive]="['bg-xgreen-enfasis']"
              href="#"
              [ngClass]="[
                'inline-flex items-center p-2 text-sm font-normal rounded-sm text-white  hover:bg-xgreen-enfasis duration-300',
                open ? 'lg:w-auto md:w-auto sm:w-full xs:w-full ' : 'w-full'
              ]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-plus-circle"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="16"></line>
                <line x1="8" y1="12" x2="16" y2="12"></line>
              </svg>
              <span
                [ngClass]="[
                  open
                    ? 'lg:hidden md:hidden ml-3 '
                    : 'flex-1 ml-3 whitespace-nowrap'
                ]"
                >Crear pedido</span
              >
            </a>
          </li>
          <!--Mis pedidos-->
          <li *ngIf="user_role?.name == 'clientB2B'">
            <a
              [routerLink]="['/orders/my_orders']"
              [routerLinkActive]="['bg-xgreen-enfasis']"
              href="#"
              [ngClass]="[
                'inline-flex items-center p-2 text-sm font-normal rounded-sm text-white hover:bg-xgreen-enfasis duration-300',
                open ? 'lg:w-auto md:w-auto sm:w-full xs:w-full ' : 'w-full'
              ]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-archive"
              >
                <polyline points="21 8 21 21 3 21 3 8"></polyline>
                <rect x="1" y="3" width="22" height="5"></rect>
                <line x1="10" y1="12" x2="14" y2="12"></line>
              </svg>
              <span
                [ngClass]="[
                  open
                    ? 'lg:hidden md:hidden ml-3 '
                    : 'flex-1 ml-3 whitespace-nowrap'
                ]"
                >Mis pedidos</span
              >
            </a>
          </li>
          <!--Informe-->
          <li *ngIf="user_role?.name == 'clientB2B'">
            <a
              [routerLink]="['/reports']"
              [routerLinkActive]="['bg-xgreen-enfasis']"
              href="#"
              [ngClass]="[
                'inline-flex items-center p-2 text-sm font-normal rounded-sm text-white hover:bg-xgreen-enfasis duration-300',
                open ? 'lg:w-auto md:w-auto sm:w-full xs:w-full ' : 'w-full'
              ]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-file-text"
              >
                <path
                  d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
                ></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
              <span
                [ngClass]="[
                  open
                    ? 'lg:hidden md:hidden ml-3 '
                    : 'flex-1 ml-3 whitespace-nowrap'
                ]"
                >Informes Operativos</span
              >
            </a>
          </li>
          <li *ngIf="user_role?.name == 'xtension-user'">
            <a
              [routerLink]="['/inbox']"
              [routerLinkActive]="['bg-xgreen-enfasis']"
              href="#"
              [ngClass]="[
                'inline-flex items-center p-2 text-sm font-normal rounded-sm text-white hover:bg-xgreen-enfasis duration-300',
                open ? 'lg:w-auto md:w-auto sm:w-full xs:w-full ' : 'w-full'
              ]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-inbox"
              >
                <polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline>
                <path
                  d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"
                ></path>
              </svg>
              <span
                [ngClass]="[
                  open
                    ? 'lg:hidden md:hidden ml-3 '
                    : 'flex-1 ml-3 whitespace-nowrap'
                ]"
                >Bandeja de entrada</span
              >
            </a>
          </li>
          <!-- Preferencias 
          <li>
            <a
              [routerLink]="['/settings']"
              [routerLinkActive]="['bg-xgreen-enfasis']"
              [ngClass]="[
                'inline-flex items-center p-2 text-sm font-normal rounded-sm text-white hover:bg-xgreen-enfasis duration-300 cursor-pointer',
                open
                  ? 'lg:w-auto md:w-auto sm:w-full xs:w-full'
                  : 'w-full transition-all',
                showSettings ? 'bg-xgreen-enfasis' : 'bg-transparent'
              ]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-settings"
              >
                <circle cx="12" cy="12" r="3"></circle>
                <path
                  d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
                ></path>
              </svg>
              <span
                [ngClass]="[
                  open
                    ? 'lg:hidden md:hidden ml-3 '
                    : 'flex-1 ml-3 whitespace-nowrap'
                ]"
                >Preferencias</span
              >
            </a>
          </li>
          -->
          <!--Ayuda
          <li>
            <a
              [routerLink]="['/help']"
              [routerLinkActive]="['bg-xgreen-enfasis']"
              href="#"
              [ngClass]="[
                'inline-flex items-center p-2 text-sm font-normal rounded-sm text-white hover:bg-xgreen-enfasis duration-300',
                open ? 'lg:w-auto md:w-auto sm:w-full xs:w-full ' : 'w-full'
              ]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-help-circle"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
              <span
                [ngClass]="[
                  open
                    ? 'lg:hidden md:hidden ml-3 '
                    : 'flex-1 ml-3 whitespace-nowrap'
                ]"
                >Ayuda</span
              >
            </a>
          </li>
          -->
        </ul>
      </div>

      <!--Profile-->

      <div
        [ngClass]="[
          'w-full profile px-4 py-2 bg-xgreen-enfasis justify-between items-center',
          open ? ' ' : 'lg:flex-row md:flex-row sm:inline-flex xs:inline-flex '
        ]"
      >
        <div class="inline-flex items-center">
          <img
            *ngIf="user?.picture"
            [src]="user?.picture"
            alt="User Profile Picture"
            [ngClass]="[
              'rounded-full object-cover',
              open
                ? 'lg:w-12 md:w-12 sm:w-12 xs:w-12'
                : 'lg:h-12 md:h-12 sm:h-12 xs:h-12'
            ]"
          />
          <ul>
            <li>
              <a href="">
                <span
                  [ngClass]="[
                    open ? 'lg:hidden md:hidden' : 'self-center ',
                    'text-white font-semibold ml-2 text-xs'
                  ]"
                  >{{
                    user?.given_name
                      ? user?.given_name
                      : (user?.name | uppercase)
                  }}</span
                >
              </a>
            </li>
            <!--
            <li>
              <a [routerLink]="['/home']">
                <span
                  [ngClass]="[
                    open ? 'lg:hidden md:hidden' : 'self-center  ',
                    ' dark:text-white ml-2 text-xs'
                  ]"
                  >Ver perfil</span
                >
              </a>
            </li>
            -->
          </ul>
        </div>

        <ul
          [ngClass]="[
            open
              ? 'lg:items-center md:items-center sm:text-right xs:text-right'
              : 'text-left',
            'flex flex-col justify-center '
          ]"
        >
          <li>
            <a
              (click)="logout()"
              [ngClass]="[
                'inline-flex justify-center items-center p-1 text-sm font-normal rounded-full text-white hover:bg-xgreen duration-300 cursor-pointer ',
                open ? 'lg:w-auto md:w-auto sm:w-full xs:w-full ' : 'w-full'
              ]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-power"
              >
                <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                <line x1="12" y1="2" x2="12" y2="12"></line>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </aside>
  <div
    [ngClass]="[
      open ? 'lg:ml-20 md:ml-20' : 'lg:ml-60 md:ml-60 ',
      ' relative w-full duration-300'
    ]"
    class=" "
  >
    <ng-content></ng-content>
  </div>
</div>
