/* eslint-disable prefer-const */
import {
  Component,
  effect,
  Injector,
  OnInit,
  runInInjectionContext,
} from '@angular/core';

import * as XLSX from 'xlsx';

import { Auth0ManagementService } from '../../shared/services/auth0/auth0-management.service';
import { ClientB2B } from '../../shared/types/dynamo/clientB2B';
import { Branch } from '../../shared/types/dynamo/branch';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SalesOrder } from '../../shared/types/dynamo/salesOrder';
import { DateTime } from 'luxon';
import { BranchSvC } from 'src/app/shared/services/api/branch.service';
import { ClientB2bService } from 'src/app/shared/services/api/client-b2b.service';
import { WorkorderService } from 'src/app/shared/services/api/workorder.service';
import { StateService } from 'src/app/shared/services/persistency/state.service';

@Component({
  selector: 'x-reports',
  templateUrl: './reports.components.html',
  standalone: false,
})
export class ReportsComponent implements OnInit {
  form!: FormGroup;

  PK = '';
  //Variables Óptica
  clientB2B: ClientB2B | undefined;
  clientB2B_SK: ClientB2B['SK'] = '';

  //Variables Sucursal
  sucursales: Branch[] = [];

  //Variables Ordenes
  workOrders: SalesOrder[] = [];
  //Variables para ordenar tabla
  isAscending = true;

  exportData: unknown[] = [];

  today: DateTime = DateTime.local();
  formatToday = this.today.toFormat('dd-MM-yyyy - hh:mm a');

  constructor(
    public auth0ManagementSvC: Auth0ManagementService,
    private branchSvC: BranchSvC,
    private clientB2BSvC: ClientB2bService,
    private fb: FormBuilder,
    private injector: Injector,
    private stateSvC: StateService,
    private workOrderSvC: WorkorderService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      sucursal: [''],
    });

    runInInjectionContext(this.injector, () => {
      effect(() => {
        const global_state = this.stateSvC.global_state();

        if (global_state) {
          this.PK = global_state.tenantData.pk;
          this.getOptic(this.PK);
          this.getBranches(this.PK);
          this.getOrdersByBranch(this.PK);
        }
      });
    });
  }

  get branchSelected(): string {
    return this.form.get('sucursal')?.value;
  }

  /** Obtener óptica */
  getOptic(pk: string) {
    this.clientB2BSvC.getClientB2B('clientB2B', pk).subscribe((res) => {
      this.clientB2B = res;
    });
  }

  /** Obtener sucursales */
  getBranches(pk: string) {
    this.branchSvC.getBranches(`${pk}_branch`).subscribe((res) => {
      this.sucursales = res;
      this.form.get('sucursal')?.setValue(this.sucursales[0].SK);
    });
  }

  /** Obtención de ordenes por sucursal seleccionada */
  getOrdersByBranch(PK: string) {
    this.form.get('sucursal')?.valueChanges.subscribe((data) => {
      if (data !== '') {
        this.workOrderSvC
          .searchOTbySucursal(`${PK}_workorder`, data)
          .subscribe({
            next: (res) => {
              this.workOrders = res;
              //Exportar datos
              this.exportData = this.workOrders.map((order) => ({
                'Fecha de ingreso': order.fecha_creacion,
                'Sucursal/Operativo': order.data.header.branch.data?.name,
                'Tecnólogo/a': order.data.header.prescriptor.data.name
                  ? order.data.header.prescriptor.data.name
                  : 'Sin asignar',
                'Rut Cliente': order.data.header.clientB2C?.data?.rut,
                Nombre: order.data.header.clientB2C?.data?.name,
                'Requiere lentes':
                  order.data.header.prescriptor.data.require_glasses,
                Derivación: order.data.header.prescriptor.data.derivation,
                'Indicaciones Derivación':
                  order.data.header.prescriptor.data.derivation_name,
              }));
            },
            error: (error) => {
              return error;
            },
          });
      } else {
        this.getAllOrders(PK);
      }
    });
  }

  /** Obtener todas las ordenes */
  getAllOrders(PK: string) {
    this.workOrderSvC.getOTs(`${PK}_workorder`).subscribe({
      next: (res) => {
        this.workOrders = res;
        //Exportar datos
        this.exportData = this.workOrders.map((order) => ({
          'Fecha de ingreso': order.fecha_creacion,
          'Sucursal/Operativo': order.data.header.branch.data?.name,
          'Tecnólogo/a': order.data.header.prescriptor.data.name
            ? order.data.header.prescriptor.data.name
            : 'Sin asignar',
          'Rut Cliente': order.data.header.clientB2C?.data?.rut,
          Nombre: order.data.header.clientB2C?.data?.name,
          'Requiere lentes': order.data.header.prescriptor.data.require_glasses,
          Derivación: order.data.header.prescriptor.data.derivation,
          'Indicaciones Derivación':
            order.data.header.prescriptor.data.derivation_name,
        }));
      },
      error: (error) => {
        return error;
      },
    });
  }
  exportToExcel(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `Informe Operativo ${this.formatToday}.xlsx`);
  }
}
