import { Component, Input } from '@angular/core';
import { SalesOrder } from '../../types/dynamo/salesOrder';
import { DateFormaterService } from '../../services/formater-services/date-formater.service';

@Component({
  selector: 'x-print-format',
  templateUrl: './print-format.component.html',
  styleUrls: ['./print-format.component.css'],
  standalone: false,
})
export class PrintFormatComponent {
  @Input() order!: SalesOrder;
  @Input() printType: string = '';

  constructor(public dateSvC: DateFormaterService) {}
}
