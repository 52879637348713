<div class="relative px-6 lg:px-8 sm:px-8">
  <div class="mx-auto max-w-3xl sm:pt-20 sm:pb-40">
    <div>
      <div
        class="flex lg:justify-center md:justify-center sm:justify-center xs:justify-start lg:mb-10 md:mb-10 sm:mb-10 sm:mt-10 xs:mb-10 xs:mt-10"
      >
        <div
          class="p-1 relative overflow-hidden rounded-full text-sm leading-6 lg:ring-1 md:ring-1 sm:ring-1 xs:ring-1 ring-gray-900/10 hover:ring-gray-900/20"
        >
          <span class="text-gray-600 font-xsubtitle">
            Conoce cómo generar una orden
            <a
              class="font-semibold text-xblue hover:text-xblue-enfasis duration-300"
              ><span class="relative" aria-hidden="true"></span>Leer más
              <span aria-hidden="true">&rarr;</span></a
            >
          </span>
        </div>
      </div>
      <div>
        <h1
          class="text-4xl font-xfont tracking-tight sm:text-center sm:text-6xl"
        >
          Xtension Opticals
        </h1>
        <p
          class="font-xsubtitle uppercase font-semibold mt-6 text-lg leading-8 text-gray-600 sm:text-center"
        >
          Salud, Tecnología, Estilo
        </p>
        <div class="mt-8 flex gap-x-4 sm:justify-center">
          <primary-button
            (click)="login()"
            [ngClass]="[
              'bg-xblue text-white hover:bg-xblue-enfasis duration-300 '
            ]"
            >Comienza aquí</primary-button
          >
        </div>
      </div>
    </div>
  </div>
</div>
