import { effect, Injectable, signal } from '@angular/core';
import { CurrentOrder } from './types/current-order';
import { StateObject } from './types/state';
import { TenantService } from './tenant.service';
import { TenantData } from './types/tenant-data';
import { StorageService } from './storage.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, last } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private readonly STORAGE_KEY_GLOBAL = 'global_state'; // Clave para la orden
  private readonly STORAGE_KEY_BRANCH = 'selected_branch'; // Clave para la sucursal seleccionada

  global_state = signal<StateObject | null>(null);
  selected_branch = signal<string | null>(null);

  constructor(
    private storageSvC: StorageService,
    private tenantSvC: TenantService,
    private router: Router
  ) {
    //Inicializamos el estado global
    effect(() => {
      this.initGlobalState();
      this.initBranchState();
    });

    // Suscripción a cambios de ruta
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => this.handleRouteChange());
  }

  private initGlobalState() {
    const tenantData = this.tenantSvC.tenant_data();
    if (!tenantData) return;
    //Estado guardado
    const storedState = this.loadStateFromStorage();
    //Estado inicial
    const initialState = this.getInitialState(tenantData);
    //Inicializamos el global state con uno de los dos estados
    this.global_state.set(storedState || initialState);
  }

  private initBranchState() {
    const storedBranch = this.storageSvC.getItem(this.STORAGE_KEY_BRANCH);
    const defaultBranch = this.getDefaultBranch();
    const initialBranch = storedBranch || defaultBranch;

    if (initialBranch) {
      this.saveBranch(initialBranch);
    }
  }

  // Carga el estado de la orden desde sessionStorage
  private loadStateFromStorage(): StateObject | null {
    return this.storageSvC.getItem(this.STORAGE_KEY_GLOBAL);
  }

  // Obtener el estado inicial
  getInitialState(tenantData: TenantData): StateObject {
    const branch = this.storageSvC.getItem(this.STORAGE_KEY_BRANCH);
    return {
      tenantData,
      currentOrder: {
        header: {
          branch: { SK: branch || '' },
        },
        configure_lenses: false,
      },
    };
  }

  // Obtiene la sucursal por defecto (primera sucursal del tenant)
  private getDefaultBranch(): string | undefined {
    const tenantData = this.tenantSvC.tenant_data();
    return tenantData?.branches[0].SK;
  }

  updateState(updateOrder: CurrentOrder) {
    const tenantData = this.tenantSvC.tenant_data();

    if (!tenantData) return;

    const updateState: StateObject = {
      tenantData: tenantData,
      currentOrder: updateOrder,
    };
    //Guardar estado global y sucursal
    this.saveGlobalState(updateState);
    const branch = updateState.currentOrder.header.branch?.SK;
    if (branch) this.saveBranch(branch);
    return updateState;
  }

  // Guarda el estado global
  saveGlobalState(updateState: StateObject) {
    this.storageSvC.setItem(this.STORAGE_KEY_GLOBAL, updateState);
  }

  // Guarda la sucursal
  saveBranch(branch: string) {
    this.storageSvC.setItem(this.STORAGE_KEY_BRANCH, branch);
    this.selected_branch.set(branch);
  }

  // Manejar cambios de ruta
  private handleRouteChange() {
    const currentUrl = this.router.url;
    if (
      !currentUrl.includes('/orders/create_order') &&
      !currentUrl.includes('/orders/order')
    ) {
      this.clearOrderStatePreservingBranch();
    }
  }

  // Limpia todo el estado excepto la sucursal seleccionada
  clearOrderStatePreservingBranch() {
    this.global_state.set(null);
    this.storageSvC.removeItem(this.STORAGE_KEY_GLOBAL);
  }
}
