import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Tab, TabType } from '../interfaces/eyeglass-tabs.interface';
import { getTypeOfFormGroup } from '../functions/getFormGroup';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EyeglassesConfigService {
  private tabs: Tab[] = [];
  activeTabIndex = 0;
  addMenuOpen = false;

  // Variables para la recarga de la vista de los tabs
  private configureEyeglassesSubject = new BehaviorSubject<number>(0);
  configureEyeglasses$ = this.configureEyeglassesSubject.asObservable();

  getTabsData(): Tab[] {
    return this.tabs;
  }

  setTabsData(tabs: Tab[]): void {
    this.tabs = tabs;
  }

  async addNewTab(tabType: TabType, active: boolean, canDelete: boolean) {
    const formGroup = (await getTypeOfFormGroup(tabType)) as FormGroup;
    if (this.tabs.length < 4) {
      const newTab: Tab = {
        label: tabType,
        content: `Este es el objeto del tipo ${tabType}`,
        active: active,
        canDelete: canDelete,
        form: formGroup,
      };
      this.tabs.push(newTab);
      this.activeTabIndex = this.tabs.length - 1;
    }
    this.addMenuOpen = false;

    return formGroup;
  }

  deleteTab(index: number) {
    //Eliminación del index dentro del arreglo
    this.tabs.splice(index, 1);

    //Si el index eliminado es el activo, se activa el anterior
    if (this.activeTabIndex >= this.tabs.length) {
      this.activeTabIndex = this.tabs.length - 1;
    }
  }

  //Recargar la vista de los tabs para que se actualice el contenido
  triggerConfigureEyeglasses() {
    // Emitir un valor diferente cada vez que se llama
    this.configureEyeglassesSubject.next(Math.random());
  }

  // Setear y retornar el valor de la combinación entre tipo de lente, material y tratamiento
  getLensesValue(
    index: number,
    lensType: string,
    material: string,
    treatment: string
  ): string {
    return `${index + 1}.- ${lensType}: ${material}, ${treatment}`;
  }
}
