import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Branch } from 'src/app/shared/types/dynamo/branch';
import { SalesOrder } from 'src/app/shared/types/dynamo/salesOrder';
import { environment as env } from 'src/environments/environment';

interface WorkOrders {
  workOrders: SalesOrder[];
}
@Injectable({
  providedIn: 'root',
})
export class WorkorderService {
  private api = env.apiUrl;

  constructor(private http: HttpClient) {}

  // xtension user

  /** Obtener todas las ordenes de todos los tenants */
  public getAllWorkorders(): Observable<SalesOrder[]> {
    return this.http.get<SalesOrder[]>(`${this.api}/tenants/workorders`);
  }

  /** Obtener todas las ordenes de un tenant que estén enviadas, confirmadas o realizadas */
  public getTenantWorkorders(rut_tenant: string): Observable<SalesOrder[]> {
    return this.http.get<SalesOrder[]>(
      `${this.api}/tenants/workorders/${rut_tenant}`
    );
  }

  /** Enviar una orden a Odoo */
  public insertOTinOdoo(workOrders: WorkOrders) {
    return this.http.post(`${this.api}/workorder/sendToOdoo`, workOrders);
  }

  /** Obtener todas las ordenes de un tenant sin importar su estado */
  public getOTs(PK: SalesOrder['PK']): Observable<SalesOrder[]> {
    return this.http.get<SalesOrder[]>(`${this.api}/workorder/${PK}`);
  }

  /** Obtener una orden de un tenant */
  public getOT(
    PK: SalesOrder['PK'],
    SK: SalesOrder['SK']
  ): Observable<SalesOrder> {
    return this.http.get<SalesOrder>(`${this.api}/workorder/${PK}/${SK}`);
  }

  /** Guardar una nueva orden */
  public addOT(OT: SalesOrder) {
    return this.http.post(`${this.api}/workorder`, OT);
  }
  /** Actualizar una orden */
  public updateOT(PK: SalesOrder['PK'], SK: SalesOrder['SK'], OT: string) {
    return this.http.put(`${this.api}/workorder/${PK}/${SK}`, OT);
  }

  /** Eliminar una orden (cambio de estado) */
  public deleteOT(workOrder: string) {
    return this.http.delete(`${this.api}/workorder`, { body: workOrder });
  }

  /** Buscar una OT por cliente final */
  public searchOTbyFinalClient(
    PK: SalesOrder['PK'],
    rut: string
  ): Observable<SalesOrder[]> {
    return this.http.get<SalesOrder[]>(
      `${this.api}/workorder/byClient/${PK}/${rut}`
    );
  }

  /** Buscar ordenes por rango de fecha */
  public searchOTbyDate(
    PK: SalesOrder['PK'],
    startDateStr: string,
    endDateStr: string
  ): Observable<SalesOrder[]> {
    return this.http.get<SalesOrder[]>(
      `${this.api}/workorder/byDate/${PK}/${startDateStr}/${endDateStr}`
    );
  }

  /** Buscar ordenes por sucursal */
  public searchOTbySucursal(
    PK: SalesOrder['PK'],
    sucursal: Branch['SK']
  ): Observable<SalesOrder[]> {
    return this.http.get<SalesOrder[]>(
      `${this.api}/workorder/byBranch/${PK}/${sucursal}`
    );
  }
}
