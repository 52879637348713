import { Component } from '@angular/core';
import { SpinnerService } from '../../services/spinner/spinner.service';

@Component({
    selector: 'x-spinner',
    template: `
    <div class="overlay" *ngIf="isLoading$ | async">
      <div class="loadingio-spinner-ellipsis-gdoioe61m7">
        <div class="ldio-gil8c6wi1mu">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  `,
    styleUrls: ['./spinner.component.css'],
    standalone: false
})
export class SpinnerComponent {
  isLoading$ = this.spinnerSvC.isLoading$;
  constructor(private spinnerSvC: SpinnerService) {}
}
