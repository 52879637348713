import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InboxComponent } from './pages/inbox.component';
@NgModule({
  declarations: [InboxComponent],
  imports: [CommonModule, FormsModule, SharedModule, ReactiveFormsModule],
  exports: [InboxComponent],
})
export class InboxModule {}
