import { Component, Input, OnInit } from '@angular/core';

import { AuthService, User } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { UserRoleAuth0 } from '../../types/auth0/user-role';
import { Auth0ManagementService } from '../../services/auth0/auth0-management.service';

@Component({
  selector: 'x-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  standalone: false,
})
export class SidebarComponent implements OnInit {
  open = false;
  showMenu = false;
  showSettings = false;

  @Input() PK = '';
  user_role: UserRoleAuth0 | null = {} as UserRoleAuth0;
  //user
  user: User | undefined | null;

  constructor(
    public auth0: AuthService,
    private auth0Management: Auth0ManagementService
  ) {}

  ngOnInit(): void {
    this.auth0.user$.subscribe((user) => {
      this.user = user;
      this.auth0Management
        .getUserRoles(user?.sub)
        .subscribe((user_role: UserRoleAuth0[]) => {
          this.user_role = user_role[0];
        });
    });
  }

  toggleNavbar() {
    this.showMenu = !this.showMenu;
  }

  toggleSettings() {
    this.showSettings = !this.showSettings;
  }

  logout() {
    this.auth0.logout({
      logoutParams: {
        returnTo: environment.baseUrl,
      },
    });
  }

  toggle() {
    this.open = !this.open;
  }
}
