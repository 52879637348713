import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'x-main-page',
    templateUrl: './main-page.component.html',
    standalone: false
})
export class MainPageComponent {
  constructor(public auth0: AuthService) {}

  login() {
    this.auth0.loginWithRedirect({
      appState: {
        target: '/home',
      },
    });
  }
}
