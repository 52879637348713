import { Component, Input } from '@angular/core';

@Component({
    selector: 'x-header-title',
    templateUrl: './header-title.component.html',
    standalone: false
})
export class HeaderTitleComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() workOrderID: string | null = '';
}
