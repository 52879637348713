import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticathedGuard } from './shared/guards/authenticated.guard';
import { AuthGuard } from '@auth0/auth0-angular';

import { ReportsComponent } from './reports/pages/reports.component';
import { MainPageComponent } from './main/pages/main-page.component';
import { MenuComponent } from './inicio/menu/menu.component';
import { InboxComponent } from './inbox/pages/inbox.component';

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
    canActivate: [AuthenticathedGuard],
    data: { requiredRole: null }, // Sin autenticación
  },
  {
    path: 'home',
    component: MenuComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./orders/orders.module').then((m) => m.OrdersModule),
    canActivate: [AuthenticathedGuard],
    data: { requiredRole: 'clientB2B' },
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthenticathedGuard],
    data: { requiredRole: 'clientB2B' },
  },
  {
    path: 'inbox',
    component: InboxComponent,
    canActivate: [AuthenticathedGuard],
    data: { requiredRole: 'xtension-user' },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
