import { Component } from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'x-navbar',
    templateUrl: './navbar.component.html',
    standalone: false
})
export class NavbarComponent {
  constructor(public auth0: AuthService) {}

  showMenu = false;
  toggleNavbar() {
    this.showMenu = !this.showMenu;
  }

  login() {
    this.auth0.loginWithRedirect({
      appState: {
        target: 'home',
      },
    });
  }

  logout() {
    this.auth0.logout({
      logoutParams: {
        returnTo: '',
      },
    });
  }
}
