<div class="space-y-2">
  <div
    *ngFor="let item of items; let i = index"
    class="border rounded-lg"
    [class.bg-gray-100]="openIndex == i"
  >
    <div
      class="flex justify-between items-center px-4 py-2 cursor-pointer"
      (click)="toggleAccordion(i)"
    >
      <div>{{ item.name }}</div>
      <span class="text-xl">&#43;</span>
    </div>
    <div *ngIf="openIndex == i" class="px-4 py-2">
      <p>{{ item.description }}</p>
    </div>
  </div>
</div>
