import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ReportsComponent } from './pages/reports.component';

@NgModule({
  declarations: [ReportsComponent],
  imports: [CommonModule, SharedModule, ReactiveFormsModule],
  exports: [ReportsComponent],
})
export class ReportsModule {}
