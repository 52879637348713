<!--Title-->
<x-header-title
  [title]="'Informes Operativos'"
  [subtitle]="
    'Aquí podrá visualizar y descargar informes de los operativos realizados.'
  "
></x-header-title>

<xcontainer class="flex flex-col space-y-7 mt-2">
  <form [formGroup]="form" class="w-full">
    <select
      id="sucursal"
      autocomplete="on"
      formControlName="sucursal"
      name="sucursal"
      class="border border-xlines text-xs rounded-sm block p-2.5 bg-white font-semibold cursor-pointer ring-0 focus:ring-0 focus:ring-xblue focus:border-xblue w-full"
    >
      <option value="">Seleccione sucursal/operativo</option>
      <option *ngFor="let sucursal of sucursales" [value]="sucursal.SK">
        {{ sucursal.data?.name }}
      </option>
    </select>
  </form>

  <table class="border w-full shadow-lg">
    <tr class="bg-[#F4F5F7] border-b">
      <th class="border-r text-xs font-semibold text-xtitles w-fit py-3">
        <div class="flex items-center justify-center">Rut Cliente</div>
      </th>
      <th class="border-r text-xs font-semibold text-xtitles w-fit">
        <div class="flex items-center justify-center">Nombre</div>
      </th>
      <th class="border-r text-xs font-semibold text-xtitles w-fit">
        <div class="flex items-center justify-center">OT Interna</div>
      </th>
      <th class="border-r text-xs font-semibold text-xtitles w-fit">
        <div class="flex items-center justify-center">OT Xtension</div>
      </th>
      <th class="border-r text-xs font-semibold text-xtitles w-fit">
        <div class="flex items-center justify-center">Requiere lentes</div>
      </th>
      <th class="border-r text-xs font-semibold text-xtitles w-fit">
        <div class="flex items-center justify-center">Derivación</div>
      </th>
      <th class="border-r text-xs font-semibold text-xtitles w-fit">
        <div class="flex items-center justify-center">
          Indicaciones Derivación
        </div>
      </th>
    </tr>
    <tr
      class="OD bg-gray-50 border text-center duration-300 fade-in"
      *ngFor="let order of workOrders"
    >
      <!--Rut Cliente-->
      <td class="border-r text-[11px] py-4">
        {{order.data.header.clientB2C?.data?.rut}}
      </td>
      <!--Nombre-->
      <td class="border-r text-[11px]">
        {{order.data.header.clientB2C?.data?.name}}
      </td>
      <td class="border-r text-[11px]">
        {{order.data.header.clientOrderNumber}}
      </td>
      <td class="border-r text-[11px]">{{order.SK}}</td>

      <!--Requiere Lentes-->
      <td class="border-r text-[10px]">
        {{order.data.header.prescriptor.data.require_glasses}}
      </td>

      <!--Derivación-->
      <td class="border-r text-[10px]">
        {{order.data.header.prescriptor.data.derivation}}
      </td>
      <!--Lugar Derivación-->
      <td class="border-r text-[11px]">
        {{order.data.header.prescriptor.data.derivation_name}}
      </td>
    </tr>
  </table>
</xcontainer>

<!--Buttons-->
<xcontainer>
  <div class="p-10 mx-auto">
    <div class="inline-flex items-center justify-center text-center w-full">
      <primary-button
        type="submit"
        [disabled]="workOrders.length == 0"
        (click)="exportToExcel()"
        [ngClass]="[
            workOrders.length >0 
              ? 'bg-xwhite text-black border border-black hover:bg-slate-50'
              : 'bg-slate-100 text-xplaceholders cursor-default'
          ]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 32 32"
        >
          <defs>
            <linearGradient
              id="IconifyId18ecabe6ed66248cc470"
              x1="4.494"
              x2="13.832"
              y1="-2092.086"
              y2="-2075.914"
              gradientTransform="translate(0 2100)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#18884f" />
              <stop offset=".5" stopColor="#117e43" />
              <stop offset="1" stopColor="#0b6631" />
            </linearGradient>
          </defs>
          <path
            fill="#185c37"
            d="M19.581 15.35L8.512 13.4v14.409A1.192 1.192 0 0 0 9.705 29h19.1A1.192 1.192 0 0 0 30 27.809V22.5Z"
          />
          <path
            fill="#21a366"
            d="M19.581 3H9.705a1.192 1.192 0 0 0-1.193 1.191V9.5L19.581 16l5.861 1.95L30 16V9.5Z"
          />
          <path fill="#107c41" d="M8.512 9.5h11.069V16H8.512Z" />
          <path
            d="M16.434 8.2H8.512v16.25h7.922a1.2 1.2 0 0 0 1.194-1.191V9.391A1.2 1.2 0 0 0 16.434 8.2"
            opacity=".1"
          />
          <path
            d="M15.783 8.85H8.512V25.1h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191"
            opacity=".2"
          />
          <path
            d="M15.783 8.85H8.512V23.8h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191"
            opacity=".2"
          />
          <path
            d="M15.132 8.85h-6.62V23.8h6.62a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191"
            opacity=".2"
          />
          <path
            fill="url(#IconifyId18ecabe6ed66248cc470)"
            d="M3.194 8.85h11.938a1.193 1.193 0 0 1 1.194 1.191v11.918a1.193 1.193 0 0 1-1.194 1.191H3.194A1.192 1.192 0 0 1 2 21.959V10.041A1.192 1.192 0 0 1 3.194 8.85"
          />
          <path
            fill="#fff"
            d="m5.7 19.873l2.511-3.884l-2.3-3.862h1.847L9.013 14.6c.116.234.2.408.238.524h.017c.082-.188.169-.369.26-.546l1.342-2.447h1.7l-2.359 3.84l2.419 3.905h-1.809l-1.45-2.711A2.355 2.355 0 0 1 9.2 16.8h-.024a1.688 1.688 0 0 1-.168.351l-1.493 2.722Z"
          />
          <path
            fill="#33c481"
            d="M28.806 3h-9.225v6.5H30V4.191A1.192 1.192 0 0 0 28.806 3"
          />
          <path fill="#107c41" d="M19.581 16H30v6.5H19.581Z" />
        </svg>
        <span>Exportar</span></primary-button
      >
    </div>
  </div>
</xcontainer>
