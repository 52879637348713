import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { AuthService } from '@auth0/auth0-angular';
import { map, Observable, of, switchMap } from 'rxjs';
import { Auth0ManagementService } from '../services/auth0/auth0-management.service';
import { UserRoleAuth0 } from '../types/auth0/user-role';

@Injectable({
  providedIn: 'root',
})

// Se implementa la interfaz `CanActivate` que controla si un usuario puede acceder a una ruta específica o no.
// Las dependencias necesarias para realizar la autenticación de usuario serán: `AuthService` que es el servicio raiz
// de Auth0, `Auth0ManagementService` que es un servicio creado por _Xtension_ para la lectura de la metadata del
// usuario logeado y finalmente `Router` que es un servicio de Angular para realizar redirecciones a rutas.
export class AuthenticathedGuard {
  constructor(
    private auth0: AuthService,
    private router: Router,
    private auth0Management: Auth0ManagementService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const requiredRole = route.data['requiredRole'] as string;

    return this.auth0.isAuthenticated$.pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          // Si no está autenticado y accede a una ruta sin restricción de roles
          if (!requiredRole) {
            return of(true);
          }
          // Redirigir a la página principal
          this.router.navigate(['/']);
          return of(false);
        }

        // Si está autenticado y la ruta es la raíz "", redirigir a home
        if (route.routeConfig?.path === '') {
          this.router.navigate(['/home']);
          return of(false);
        }

        // Si no se requiere un rol específico, permitir el acceso
        if (!requiredRole) {
          return of(true);
        }

        // Verificar los roles del usuario
        return this.auth0.user$.pipe(
          switchMap((user) =>
            this.auth0Management.getUserRoles(user?.sub).pipe(
              map((userRoles: UserRoleAuth0[]) => {
                const hasRequiredRole = userRoles.some(
                  (role) => role.name === requiredRole
                );
                if (!hasRequiredRole) {
                  // Redirigir a "home" si no tiene el rol requerido
                  this.router.navigate(['/home']);
                }
                return hasRequiredRole;
              })
            )
          )
        );
      })
    );
  }
}
