<div class="container-table" *ngIf="printType == 'clientB2B'">
  <!--Sección Order Partner-->
  <div class="column border-right">
    <div class="partner-data">
      <h1>Óptica {{ order.data.header.clientB2B.data.fantasy_name }}</h1>
    </div>
    <h4>
      Orden de Trabajo
      <span class="font-bold text-xblue">{{ order.SK }}</span>
    </h4>
    <section class="general-data">
      <table>
        <tr>
          <th>Fecha creación</th>
          <th>N° OT</th>
          <th>Sucursal</th>
          <th>Urgente</th>
          <th>Fecha entrega</th>
        </tr>
        <tr>
          <td>{{ dateSvC.dateFormater(1, order.fecha_creacion) }}</td>
          <td>{{ order.SK }}</td>
          <td>{{ order.data.header.branch.data?.name }}</td>
          <td>
            {{
              order.data.header.express_order
                ? order.data.header.express_order
                : 'No'
            }}
          </td>
          <td>
            {{
              order.fecha_entrega
                ? dateSvC.dateFormater(1, order.fecha_entrega)
                : ''
            }}
          </td>
        </tr>
      </table>
    </section>
    <section class="data-client" *ngIf="order.data.header.clientB2C">
      <h4 class="bold">Datos Cliente</h4>
      <table>
        <tr>
          <th>Rut Cliente</th>
          <th>Nombre</th>
          <th>Teléfono</th>
        </tr>
        <tr>
          <td>{{ order.data.header.clientB2C.data.rut }}</td>
          <td>{{ order.data.header.clientB2C.data.name }}</td>
          <td>{{ order.data.header.clientB2C.data.phone }}</td>
        </tr>
        <tr>
          <th>Email</th>
          <th>Dirección</th>
        </tr>
        <tr>
          <td>{{ order.data.header.clientB2C.data.email }}</td>
          <td>{{ order.data.header.clientB2C.data.shipping_address }}</td>
        </tr>
      </table>
    </section>
    <section class="prescription">
      <h4 class="bold">Prescripción</h4>
      <p>
        Tipo de Foco:
        <span class="bold">{{
          order.data.body.prescription_detail?.focus
        }}</span>
      </p>
      <div *ngFor="let o of order.data.body.prescription_detail?.prescription">
        <table class="table-prescription">
          <tr>
            <th></th>
            <th>Esfera</th>
            <th>Cilindro</th>
            <th>Eje</th>
            <th>Altura</th>
            <th>Adición</th>
          </tr>
          <tr>
            <td>OD</td>
            <td>{{ o.rightEye.sph }}</td>
            <td>{{ o.rightEye.cyl }}</td>
            <td>{{ o.rightEye.axys }}</td>
            <td>{{ o.rightEye.height }}</td>
            <td>{{ o.rightEye.add }}</td>
          </tr>
          <tr>
            <td>OI</td>
            <td>{{ o.leftEye.sph }}</td>
            <td>{{ o.leftEye.cyl }}</td>
            <td>{{ o.leftEye.axys }}</td>
            <td>{{ o.leftEye.height }}</td>
            <td>{{ o.leftEye.add }}</td>
          </tr>
        </table>
        <table style="width: auto">
          <tr>
            <th>Distancia Pupilar</th>
          </tr>
          <tr>
            <td>{{ o.pupillaryDistance }}</td>
          </tr>
        </table>
      </div>
    </section>
    <section class="features">
      <h4 class="bold">Trabajos</h4>
      <div *ngFor="let o of order.data.body.eyeglasses">
        <p class="pt-2">
          Lente: <span class="bold">{{ o.type }}</span>
        </p>
        <table>
          <tr>
            <th>Material</th>
            <th>Montaje</th>
            <th>Tratamiento</th>
            <th>Armazón</th>
            <th>Teñido</th>
          </tr>
          <tr>
            <td>{{ o.material }}</td>
            <td>{{ o.assembly }}</td>
            <td>{{ o.treatment }}</td>
            <td>{{ o.frame }}</td>
            <td>{{ o.tinted }}</td>
          </tr>
        </table>
      </div>

      <section class="observations">
        <h4 class="bold">Observaciones</h4>
        <div>
          <p>
            {{
              order.data.body.observations
                ? order.data.body.observations
                : 'No hay observaciones.'
            }}
          </p>
        </div>
      </section>
    </section>
  </div>
  <!--Sección Order Xtension-->
  <div class="column">
    <div class="partner-data">
      <h1>Óptica Xtension Opticals SpA</h1>
    </div>
    <h4>
      Orden de Trabajo <span class="font-bold text-xblue">{{ order.SK }}</span>
    </h4>
    <section class="general-data">
      <table>
        <tr>
          <th>Fecha creación</th>
          <th>N° OT</th>
          <th>Sucursal</th>
          <th>Urgente</th>
          <th>Fecha entrega</th>
        </tr>
        <tr>
          <td>{{ dateSvC.dateFormater(1, order.fecha_creacion) }}</td>
          <td>{{ order.SK }}</td>
          <td>{{ order.data.header.branch.data?.name }}</td>
          <td>
            {{
              order.data.header.express_order
                ? order.data.header.express_order
                : 'No'
            }}
          </td>
          <td>
            {{
              order.fecha_entrega
                ? dateSvC.dateFormater(1, order.fecha_entrega)
                : ''
            }}
          </td>
        </tr>
      </table>
    </section>
    <section class="data-client">
      <h4 class="bold">Datos Óptica</h4>
      <table>
        <tr>
          <th>Rut</th>
          <th>Nombre</th>
          <th>Teléfono</th>
        </tr>
        <tr>
          <td>{{ order.data.header.clientB2B.data.rut }}</td>
          <td>{{ order.data.header.clientB2B.data.fantasy_name }}</td>
          <td>{{ order.data.header.branch.data?.phone }}</td>
        </tr>
        <tr>
          <th>Email</th>
          <th>Dirección</th>
        </tr>
        <tr>
          <td>{{ order.data.header.branch.data?.email }}</td>
          <td>{{ order.data.header.branch.data?.address }}</td>
        </tr>
      </table>
    </section>
    <section class="prescription">
      <h4 class="bold">Prescripción</h4>
      <p>
        Tipo de Foco:
        <span class="bold">{{
          order.data.body.prescription_detail?.focus
        }}</span>
      </p>
      <div *ngFor="let o of order.data.body.prescription_detail?.prescription">
        <table class="table-prescription">
          <tr>
            <th></th>
            <th>Esfera</th>
            <th>Cilindro</th>
            <th>Eje</th>
            <th>Altura</th>
            <th>Adición</th>
          </tr>
          <tr>
            <td>OD</td>
            <td>{{ o.rightEye.sph }}</td>
            <td>{{ o.rightEye.cyl }}</td>
            <td>{{ o.rightEye.axys }}</td>
            <td>{{ o.rightEye.height }}</td>
            <td>{{ o.rightEye.add }}</td>
          </tr>
          <tr>
            <td>OI</td>
            <td>{{ o.leftEye.sph }}</td>
            <td>{{ o.leftEye.cyl }}</td>
            <td>{{ o.leftEye.axys }}</td>
            <td>{{ o.leftEye.height }}</td>
            <td>{{ o.leftEye.add }}</td>
          </tr>
        </table>
        <table style="width: auto">
          <tr>
            <th>Distancia Pupilar</th>
          </tr>
          <tr>
            <td>{{ o.pupillaryDistance }}</td>
          </tr>
        </table>
      </div>
    </section>
    <section class="features">
      <h4 class="bold">Trabajos</h4>
      <div *ngFor="let o of order.data.body.eyeglasses">
        <p class="pt-2">
          Lente: <span class="bold">{{ o.type }}</span>
        </p>
        <table>
          <tr>
            <th>Material</th>
            <th>Montaje</th>
            <th>Tratamiento</th>
            <th>Armazón</th>
            <th>Teñido</th>
          </tr>
          <tr>
            <td>{{ o.material }}</td>
            <td>{{ o.assembly }}</td>
            <td>{{ o.treatment }}</td>
            <td>{{ o.frame }}</td>
            <td>{{ o.tinted }}</td>
          </tr>
        </table>
      </div>

      <section class="observations">
        <h4 class="bold">Observaciones</h4>
        <div>
          <p>
            {{
              order.data.body.observations
                ? order.data.body.observations
                : 'No hay observaciones.'
            }}
          </p>
        </div>
      </section>
    </section>
  </div>
</div>

<div class="container-table-2" *ngIf="printType == 'xtension_user'">
  <!--Sección Order Xtension-->
  <div class="column">
    <h4>
      Orden de Trabajo
      <span class="font-bold text-xblue">{{ order.SK }}</span>
    </h4>
    <section class="general-data">
      <table>
        <tr>
          <th>Fecha creación</th>
          <th>N° OT</th>
          <th>Sucursal</th>
          <th>Urgente</th>
          <th>Fecha entrega</th>
        </tr>
        <tr>
          <td>{{ dateSvC.dateFormater(1, order.fecha_creacion) }}</td>
          <td>{{ order.SK }}</td>
          <td>{{ order.data.header.branch.data?.name }}</td>
          <td>
            {{
              order.data.header.express_order
                ? order.data.header.express_order
                : 'No'
            }}
          </td>
          <td>{{ dateSvC.dateFormater(1, order.fecha_creacion) }}</td>
        </tr>
      </table>
    </section>
    <section class="data-client">
      <h4 class="bold">Datos Óptica</h4>
      <table>
        <tr>
          <th>Rut</th>
          <th>Nombre</th>
          <th>Teléfono</th>
        </tr>
        <tr>
          <td>{{ order.data.header.clientB2B.data.rut }}</td>
          <td>{{ order.data.header.clientB2B.data.fantasy_name }}</td>
          <td>{{ order.data.header.branch.data?.phone }}</td>
        </tr>
        <tr>
          <th>Email</th>
          <th>Dirección</th>
        </tr>
        <tr>
          <td>{{ order.data.header.branch.data?.email }}</td>
          <td>{{ order.data.header.branch.data?.address }}</td>
        </tr>
      </table>
    </section>
    <section class="prescription">
      <h4 class="bold">Prescripción</h4>
      <p>
        Tipo de Foco:
        <span class="bold">{{
          order.data.body.prescription_detail?.focus
        }}</span>
      </p>
      <div *ngFor="let o of order.data.body.prescription_detail?.prescription">
        <table class="table-prescription">
          <tr>
            <th></th>
            <th>Esfera</th>
            <th>Cilindro</th>
            <th>Eje</th>
            <th>Altura</th>
            <th>Adición</th>
          </tr>
          <tr>
            <td>OD</td>
            <td>{{ o.rightEye.sph }}</td>
            <td>{{ o.rightEye.cyl }}</td>
            <td>{{ o.rightEye.axys }}</td>
            <td>{{ o.rightEye.height }}</td>
            <td>{{ o.rightEye.add }}</td>
          </tr>
          <tr>
            <td>OI</td>
            <td>{{ o.leftEye.sph }}</td>
            <td>{{ o.leftEye.cyl }}</td>
            <td>{{ o.leftEye.axys }}</td>
            <td>{{ o.leftEye.height }}</td>
            <td>{{ o.leftEye.add }}</td>
          </tr>
        </table>
        <table style="width: auto">
          <tr>
            <th>Distancia Pupilar</th>
          </tr>
          <tr>
            <td>{{ o.pupillaryDistance }}</td>
          </tr>
        </table>
      </div>
    </section>
    <section class="features">
      <h4 class="bold">Trabajos</h4>
      <div *ngFor="let o of order.data.body.eyeglasses">
        <p class="pt-2">
          Lente: <span class="bold">{{ o.type }}</span>
        </p>
        <table>
          <tr>
            <th>Material</th>
            <th>Montaje</th>
            <th>Tratamiento</th>
            <th>Armazón</th>
            <th>Teñido</th>
          </tr>
          <tr>
            <td>{{ o.material }}</td>
            <td>{{ o.assembly }}</td>
            <td>{{ o.treatment }}</td>
            <td>{{ o.frame }}</td>
            <td>{{ o.tinted }}</td>
          </tr>
        </table>
      </div>

      <section class="observations">
        <h4 class="bold">Observaciones</h4>
        <div>
          <p>
            {{
              order.data.body.observations
                ? order.data.body.observations
                : 'No hay observaciones.'
            }}
          </p>
        </div>
      </section>
    </section>
  </div>
</div>
