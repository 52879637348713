import { Component, Input } from '@angular/core';

@Component({
    selector: 'xcontainer',
    templateUrl: './container.component.html',
    standalone: false
})
export class ContainerComponent {
  @Input() class = '';
}
